<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x mt-5">
      <div class="cell small-12 medium-9">
        <ul class="page-indicator">
          <li :class="{ active : page === 'overview' }" @click="goTo('/start')">Overview</li>
          <li :class="{ active : page === 'population' }" @click="goTo('/population')">Population</li>
          <li :class="{ active : page === 'drugTimeline' }" @click="goTo('/drug-timeframe')">Drug/Timeframe</li>
          <li :class="{ active : page === 'costs' }" @click="goTo('/costs')">Cost</li>
          <li :class="{ active : page === 'dosing' }" @click="goTo('/dosing')">Dosing</li>
          <li :class="{ active : page === 'patient-numbers' }" @click="goTo('/patient-numbers')">Patient numbers</li>
          <li :class="{ active : page === 'share' }" @click="goTo('/share')">Share</li>
          <li :class="{ active : page === 'summary' }" @click="goTo('/summary')">Summary</li>
        </ul>
      </div>
      <div class="cell small-12 medium-3">
        <ul class="nav">
          <li class="instructions" @click="goTo('/instructions')">Instructions</li>
          <li class="indication" @click="goTo('/indications')">Indication</li>
          <li class="ref" @click="goTo('/references')">Ref</li>
          <li class="menu" @click="menu = true" v-if="menu === false">Menu</li>
          <li class="pi">
            <a
              target="_blank"
              style="display:inline-block;width:30px;height:30px;"
              href="https://biib.mobi/uk-byooviz-pi">PI
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu-pane" v-if="menu === true">
      <span class="menu-active" @click="menu = false" v-if="menu === true">Menu</span>
      <ul>
        <li @click="goTo('/start')">Overview</li>
        <li @click="goTo('/instructions')">Instructions</li>
        <li @click="goTo('/indications')">Indications</li>
        <li @click="goTo('/references')">References</li>
        <li @click="reset">Reset Model</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    reset() {
      this.$store.commit('clear');
      this.$router.push('/start');
    },
    goTo(destination) {
      if (this.$route.path !== destination) {
        if (destination === '/summary') {
          this.$router.push('/share');
        } else {
          this.$router.push(destination);
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .page-indicator {
    margin: 0;
    padding: 0;

    li {
      font-family: "Helvetica Neue-Condensed", "Helvetica Neue",
        Helvetica, Arial, "Lucida Grande", sans-serif;
      font-weight: 500;
      font-size: 15px;
      float: left;
      list-style-type: none;
      text-transform: uppercase;
      color: orange;
      padding: 10px 9px 5px 9px;
      border-bottom: 3px solid #ffa300;
      cursor: pointer;
      letter-spacing: -1px;

      &:hover,
      &:active,
      &.active {
        color: #0075c0;
        border-bottom: 3px solid #0075c0;
      }

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .nav {
    margin: 10px 0 0 0;
    padding: 0;

    li {
      float: left;
      list-style-type: none;
      font-size: 0;
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-size: 30px auto;
      background-position: center center;
      cursor: pointer;

      &.instructions {
        background-image: url('../assets/menu_buttons/instruction_button.png');
        &:hover,
        &:focus {
          background-image: url('../assets/menu_buttons/instruction_button_active.png');
        }
      }
      &.indication {
        background-image: url('../assets/menu_buttons/indication_button.png');
        &:hover,
        &:focus {
          background-image: url('../assets/menu_buttons/indication_button_active.png');
        }
      }
      &.ref {
        background-image: url('../assets/menu_buttons/ref_button.png');
        &:hover,
        &:focus {
          background-image: url('../assets/menu_buttons/ref_button_active.png');
        }
      }
      &.menu {
        background-image: url('../assets/menu_buttons/menu_button.png');
        &:hover,
        &:focus {
          background-image: url('../assets/menu_buttons/menu_button_close.png');
        }
      }
      &.pi {
        background-image: url('../assets/menu_buttons/PI_button.png');
        &:hover,
        &:focus {
          background-image: url('../assets/menu_buttons/PI_button.png');
        }
      }
    }
  }
  .menu-pane {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: #cbc9c7;
    width: 33%;
    padding: 140px 20px 20px 60px;

    .menu-active {
      position:absolute;
      top: 40px;
      right: 30%;
      font-size: 0;
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-size: 30px auto;
      background-position: center center;
      cursor: pointer;
      background-image: url('../assets/menu_buttons/menu_button_close.png');
      &:hover,
      &:focus {
        background-image: url('../assets/menu_buttons/menu_button.png');
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        font-weight: 400;
        font-size: 22px;
        padding-bottom: 20px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
